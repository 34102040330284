import { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "./Exercise.module.scss";
import Header from "components/header/Header";
import { handleNaitve } from "utils/native";
import { useQuery } from "@tanstack/react-query";
import { restfetcher, QueryKeys, getClient } from "utils/queryClient";
import { handleExVideos } from "utils/exercises";
import ThumbImg from "assets/img/library_thumb_bridge.png";

interface Props {
  programIdx: string;
  handleClose: () => void;
  libraryGroup: number;
}

const VideoView = ({ programIdx, handleClose, libraryGroup }: Props) => {
  const queryClient = getClient();

  const navigate = useNavigate();
  let isFullScreen = false;
  const headerType = localStorage.getItem("headerType");
  const mobileType = navigator.userAgent.toLowerCase();
  const [videos, setVideos] = useState<any>([]);
  const playerRef = useRef<HTMLVideoElement>(null);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [playTime, setPlayTime] = useState(0);
  const [count, setCount] = useState(0);
  const [iscontrols, setIscontrols] = useState(false);
  const [fullScreens, setFullScreen] = useState(isFullScreen);
  const [videoLoaded, setVideoLoaded] = useState(false);

  const healthmoviesApi = useQuery({
    queryKey: [QueryKeys.HEALTHMOVIES],
    queryFn: () =>
      restfetcher({
        method: "POST",
        path: "v1/app/healthmovies",
        params: {
          programIdx: programIdx,
        },
      }),
  });

  useEffect(() => {
    healthmoviesApi.refetch();
    if (mobileType.indexOf("android") > -1) {
      document.addEventListener("fullscreenchange", onfullscreenchange, false);
      return () => {
        document.removeEventListener(
          "fullscreenchange",
          onfullscreenchange,
          false
        );
        handleNaitve(`{"cmd":"orientation", "value":"portrait"}`);
        queryClient.removeQueries({ queryKey: [QueryKeys.HEALTHMOVIES] });
      };
    }
  }, []);

  useEffect(() => {
    const { isSuccess, data } = healthmoviesApi;
    if (isSuccess && data?.result === "success") {
      const { exerciseMovieList } = data;
      if (exerciseMovieList?.exerciseProgramInfo) {
        console.log(
          handleExVideos(exerciseMovieList?.exerciseProgramInfo, libraryGroup)
        );

        setVideos(
          handleExVideos(exerciseMovieList?.exerciseProgramInfo, libraryGroup)
        );
      } else {
        navigate(-1);
      }
    }
  }, [healthmoviesApi.isSuccess, healthmoviesApi.data]);

  //영상 재생 시간 or 수 체크
  useEffect(() => {
    if (videos[currentVideoIndex] && videos[currentVideoIndex]?.type === 1) {
      setIscontrols(false);
      setPlayTime(playTime + 1);
    }
  }, [currentTime]);

  useEffect(() => {
    if (
      videos[currentVideoIndex] &&
      videos[currentVideoIndex]?.type === 1 &&
      videos[currentVideoIndex]?.time === playTime
    ) {
      handleNextVideo();
    }
  }, [playTime]);

  useEffect(() => {
    if (videos[0]?.url) {
      onPlay();
    } else {
      setVideoLoaded(false);
    }
  }, [videos]);

  useEffect(() => {
    if (
      videos[currentVideoIndex] &&
      videos[currentVideoIndex]?.name === "exercise" &&
      (videos[currentVideoIndex]?.type === 2 ||
        videos[currentVideoIndex]?.type === 5) &&
      videos[currentVideoIndex]?.repeate + 1 === count
    ) {
      handleNextVideo();
    }
  }, [count]);

  const handleVideoEnded = () => {
    if (
      videos[currentVideoIndex] &&
      videos[currentVideoIndex]?.name !== "rest"
    ) {
      handleNextVideo();
    }
  };

  const handleTimeUpdate = () => {
    const currentTime = playerRef?.current?.currentTime || 0;
    const timeUpdate = Math.floor(currentTime % 60);

    if (videos[currentVideoIndex]?.type === 1) {
      setCurrentTime(timeUpdate);
    } else if (
      (videos[currentVideoIndex]?.type === 2 ||
        videos[currentVideoIndex]?.type === 5) &&
      videos[currentVideoIndex]?.name === "exercise" &&
      currentTime < 0.001
    ) {
      setCount(count + 1);
    }
  };

  const handleNextVideo = () => {
    const nextVideoIndex = currentVideoIndex + 1;
    setCurrentVideoIndex(nextVideoIndex);
    setPlayTime(0);
    setCount(0);
  };

  const onfullscreenchange = (e: any) => {
    if (isFullScreen) {
      handleNaitve(`{"cmd":"orientation", "value":"portrait"}`);
    } else {
      handleNaitve(`{"cmd":"orientation", "value":"landscape"}`);
    }
    isFullScreen = !isFullScreen;
    setFullScreen(isFullScreen);
  };

  const onPlay = () => {
    setTimeout(() => {
      if (playerRef.current) {
        setVideoLoaded(true);

        // 재생 중이 아닌 경우에만 play() 호출
        if (playerRef.current.paused) {
          playerRef.current
            .play()
            .then(() => console.log("Video is playing"))
            .catch((error) => {
              console.error("Failed to play video:", error.message);
            });
        }
      }
    }, 500);
  };

  return (
    <div
      className={`${style.view} openUp`}
      style={{ paddingTop: `${headerType === "60px" ? "101px" : "61px"}` }}
    >
      <Header
        title={
          libraryGroup === 5
            ? "Studio_Library_Group_Name_01_0"
            : "Category_Name_9_0"
        }
        isClose={true}
        onClick={handleClose}
        select={undefined}
        isBack={false}
      />
      <div
        className={style.viewArea}
        style={{ height: `calc(100vh - ${headerType})` }}
      >
        <video
          ref={playerRef}
          src={videos[currentVideoIndex]?.url}
          onEnded={handleVideoEnded}
          controls={iscontrols}
          autoPlay={videoLoaded}
          onTimeUpdate={handleTimeUpdate}
          loop={
            videos[currentVideoIndex]?.name !== "guide" &&
            videos[currentVideoIndex]?.name !== "start" &&
            videos[currentVideoIndex]?.name !== "end"
          }
          poster={ThumbImg}
          onClick={() => setIscontrols(!iscontrols)}
          //playsInline
        />
      </div>
    </div>
  );
};

export default VideoView;
