import {
  RESTMOVIE,
  STARTMOVIE,
  ENDMOVIE,
  RESTMOVIE_EN,
  ENDMOVIE_EN,
  STARTMOVIE_EN,
} from "datas/common";

export const handleExVideos = (
  datas: VideoMovieDatasType,
  libraryGroup: number
) => {
  const movieDatas = datas?.exerciseGroupInfos;
  const isENLanguage = [7, 10, 11, 12].includes(libraryGroup);

  let newVideos: VideoBaseMovieType[] = [
    {
      url: isENLanguage ? STARTMOVIE_EN : STARTMOVIE,
      repeate: 1,
      type: 0,
      name: "start",
    },
  ];

  for (let i = 0; i < movieDatas?.length; i++) {
    const datas = movieDatas[i];
    const last = i === movieDatas?.length - 1;
    let playMovie: VideoRepeateMovieType[] | VideoBaseMovieType[] = [];

    //그룹타입, 0 : 일반 , 1 : 순환, 2 : 순차
    if (datas.groupType === 0) {
      playMovie = handleNomalEx(datas.exerciseInfos[0], libraryGroup);
    } else if (datas.groupType === 2) {
      for (let i = 0; i < datas.exerciseInfos.length; i++) {
        const groupSequentialMovie = handleNomalEx(
          datas.exerciseInfos[i],
          libraryGroup
        );
        playMovie = [...playMovie, ...groupSequentialMovie];
      }
    } else {
      let setgroupCycleMovie: VideoTimeMovieType[] | VideoBaseMovieType[] = [];
      for (let i = 0; i < datas.exerciseInfos.length; i++) {
        const groupCycleMovie = handleGroupCycle(
          datas.exerciseInfos[i],
          libraryGroup
        );
        setgroupCycleMovie = [...setgroupCycleMovie, ...groupCycleMovie];
      }

      //한 세트 끝날때 마다 휴식영상 추가
      setgroupCycleMovie.push({
        url: isENLanguage ? RESTMOVIE_EN : RESTMOVIE,
        time: datas.groupRestSecond,
        name: "rest",
        type: 1,
      });
      // //만들어진 운동 세트수 만큼 반복 추가하기
      playMovie = Array.from(
        { length: Number(datas.groupSetCount) },
        () => setgroupCycleMovie
      ).flat();
    }

    //마무리 영상 추가
    if (last) {
      playMovie[playMovie.length - 1] = {
        url: isENLanguage ? ENDMOVIE_EN : ENDMOVIE,
        repeate: 1,
        type: 0,
        name: "end",
      };
    }
    newVideos = [...newVideos, ...playMovie];
  }
  return newVideos;
};
// 165 32591

export const addExercisemovie = (
  movie: VideoMovieType,
  libraryGroup: number
) => {
  const baseVideo = {
    url: movie.exerciseUrl,
    name: "exercise",
    type: movie.contentType,
    libraryId: movie.libraryId,
    calorie: movie.calorie,
    goal: movie.time,
  };

  if (movie.contentType === 2) {
    return {
      ...baseVideo,
      repeate:
        libraryGroup === 5 || libraryGroup === 10
          ? movie.repsPerSet / 4
          : movie.repsPerSet,
    };
  }

  if (movie.contentType === 5) {
    return {
      ...baseVideo,
      repeate: movie.repsPerSet,
    };
  }

  return {
    ...baseVideo,
    time: movie.repsPerSet,
  };
};

export const handleNomalEx = (movie: VideoMovieType, libraryGroup: number) => {
  //카운트 영상 추가
  let videos: any = [];

  const isENLanguage = [7, 10, 11, 12].includes(libraryGroup);

  videos.push(addExercisemovie(movie, libraryGroup));

  //휴식 추가
  videos.push({
    url: isENLanguage ? RESTMOVIE_EN : RESTMOVIE,
    time: movie.restSecond,
    name: "rest",
    type: 1,
  });

  videos = Array.from({ length: Number(movie.setCount) }, () => videos).flat();

  //맨앞에 가이드 영상 추가
  if (libraryGroup === 6) {
    videos.unshift({
      url: movie.exerciseInfoUrl,
      repeate: 1,
      type: 0,
      name: "guide",
    });
  }

  return videos;
};

export const handleGroupCycle = (
  movie: VideoMovieType,
  libraryGroup: number
) => {
  //console.log("그룹순환운동")
  const videos: any =
    libraryGroup === 5 || libraryGroup === 10
      ? []
      : [
          {
            //운동안내
            url: movie.exerciseInfoUrl,
            repeate: 1,
            type: 0,
            name: "guide",
          },
        ];
  videos.push(addExercisemovie(movie, libraryGroup));
  return videos;
};
