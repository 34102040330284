import { useState, useEffect, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { restfetcher, QueryKeys, getClient } from "utils/queryClient";
import style from "./Exercise.module.scss";
import RecommendedEX from "components/resultCards/recommendedEX/RecommendedEX";
import Nodata from "components/nodata/Nodata";
import View from "./View";
import InfiniteScroll from "components/infiniteScroll/InfiniteScroll";
import UpBtn from "components/btns/upBtn/UpBtn";
import RecommendedEXCard from "components/resultCards/recommendedEX/RecommendedEXCard";
import useBodyScrollLock from "hooks/useBodyScrollLock";
import { useTranslation } from "react-i18next";
// import RecommendViewer from "./RecommendViewer";

const Recommend = ({ select = -1 }) => {
  const queryClient = getClient();
  const { i18n } = useTranslation();

  const listRef = useRef<HTMLLIElement>(null);
  const token =
    typeof sessionStorage !== "undefined"
      ? sessionStorage?.getItem("token")
      : null;
  const [page, setPage] = useState(1);
  const [totalpage, setTotalpage] = useState(0);
  const [datas, setDatas] = useState<any[] | null>(null);
  const [video, setVideo] = useState<null | string>(null);
  const { lockScroll, openScroll } = useBodyScrollLock();

  const recommendApi = useQuery({
    queryKey: [QueryKeys.RECOMMEND],
    queryFn: () =>
      restfetcher({
        method: "POST",
        path: "v1/app/recommend",
        params: {
          sort: select,
          page: page,
          language: i18n.language,
        },
      }),
  });

  useEffect(() => {
    if (
      page > 0 &&
      !recommendApi.isLoading &&
      (totalpage > page || totalpage === page) &&
      token
    ) {
      recommendApi.refetch();
    }
  }, [page]);

  useEffect(() => {
    setDatas(null);
    if (token) {
      queryClient.removeQueries({ queryKey: [QueryKeys.RECOMMEND] });
    }
    if (page === 1) {
      if (token) recommendApi.refetch();
    } else {
      setPage(1);
    }
  }, [select]);

  useEffect(() => {
    const { isSuccess, data } = recommendApi;
    if (isSuccess && data?.result === "success") {
      const { recommend, totalpage } = data;
      let newData = datas === null ? recommend : datas?.concat(recommend);
      setTotalpage(totalpage);
      setDatas(newData);

      queryClient.removeQueries({ queryKey: [QueryKeys.RECOMMEND] });
    }
  }, [recommendApi.isSuccess, recommendApi.data]);

  useEffect(() => {
    if (video) {
      lockScroll();
    } else {
      openScroll();
    }
  }, [video]);

  const rowChildren = (data: any) => {
    return (
      <RecommendedEX
        data={data}
        onClick={setVideo}
        key={`${data.bodypart}_${data.measureidx}`}
        liLength={data?.movieList?.length}
      >
        {data?.movieList?.map((exercise: any) => (
          <li className={style.list} key={`${exercise?.idx}`} ref={listRef}>
            <RecommendedEXCard
              onClick={() => exercise?.url && setVideo(exercise?.url)}
              data={exercise}
            />
          </li>
        ))}
      </RecommendedEX>
    );
  };

  return (
    <div className={style.recommend}>
      {token ? (
        datas ? (
          datas?.length > 0 ? (
            <section>
              <InfiniteScroll
                datas={datas}
                page={page}
                setPage={setPage}
                refetch={() => recommendApi.refetch()}
                rowChildren={rowChildren}
              />
              {video && (
                <View video={video} handleClose={() => setVideo(null)} />
                // <RecommendViewer
                //   video={video}
                //   handleClose={() => setVideo(null)}
                // />
              )}
              <UpBtn />
            </section>
          ) : (
            <Nodata text="Mobile_Exercise_Details_0_Announcement_1_0" />
          )
        ) : (
          <section></section>
        )
      ) : (
        <Nodata text="Mobile_Exercise_Details_0_Announcement_1_0" />
      )}
    </div>
  );
};

export default Recommend;
