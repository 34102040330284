export const RESTMOVIE =
  "https://cdn.metafamily.world/common/exercise/h264/2_BrakeTime.mp4";
export const STARTMOVIE =
  "https://cdn.metafamily.world/common/exercise/h264/1_Start.mp4";
export const ENDMOVIE =
  "https://cdn.metafamily.world/common/exercise/h264/3_End.mp4";

export const STARTMOVIE_EN = `https://cdn.metafamily.world/common/exercise/av1/1_Start_EN.mp4`;
export const ENDMOVIE_EN = `https://cdn.metafamily.world/common/exercise/av1/3_End_EN.mp4`;
export const RESTMOVIE_EN = `https://cdn.metafamily.world/common/exercise/av1/2_BrakeTime_EN.mp4`;

export const sortMenus = [
  {
    key: -1,
    value: "Mobile_Report_Sort_1_0",
  },
  {
    key: 1,
    value: "Mobile_Report_Sort_2_0",
  },
];

export const postureState = [
  {
    key: "front",
    value: "Mobile_Main_MyResult_Tab_1_0",
  },
  {
    key: "side",
    value: "Mobile_Main_MyResult_Tab_2_0",
  },
  {
    key: "back",
    value: "Mobile_Main_MyResult_Tab_3_0",
  },
];

export const emails = [
  {
    key: 0,
    value: "Mobile_Common_InsertBox_Text_2_0",
  },
  {
    key: 1,
    value: "naver.com",
  },
  {
    key: 2,
    value: "gmail.com",
  },
  {
    key: 3,
    value: "daum.net",
  },
  {
    key: 4,
    value: "kakao.com",
  },
  {
    key: 5,
    value: "hanmail.net",
  },
  {
    key: 6,
    value: "nate.com",
  },
];

export const measuretype = [
  {
    key: "turtleneck",
    value: "Mobile_Main_InspectionResult_1_Name_0",
  },
  {
    key: "neckside",
    value: "Mobile_Main_InspectionResult_2_Name_0",
  },

  {
    key: "shoulderbalance",
    value: "Mobile_Main_InspectionResult_3_Name_0",
  },
  {
    key: "pelvisbalance",
    value: "Mobile_Main_InspectionResult_4_Name_0",
  },
  {
    key: "pelvictilt",
    value: "Mobile_Main_InspectionResult_5_Name_0",
  },
  {
    key: "kneeflexion",
    value: "Mobile_Main_InspectionResult_6_Name_0",
  },
];

export const genderDatas = [
  {
    key: "MAN",
    value: "Mobile_UserInfo_Sex_Male_0",
  },
  {
    key: "WOMAN",
    value: "Mobile_UserInfo_Sex_Female_0",
  },
];

export const languageList = [
  {
    key: "ko",
    value: "Mobile_UserInfo_Language_Korean_0",
  },
  {
    key: "en",
    value: "Mobile_UserInfo_Language_English_0",
  },
  // {
  //     key : "zh",
  //     value : "중국어"
  // },
  {
    key: "jp",
    value: "Mobile_UserInfo_Language_Japanese_0",
  },
];

export const passwordInfo = [
  {
    value: "Mobile_Join_Password_03",
  },
  {
    value: "Mobile_Join_Password_04",
  },
  {
    value: "Mobile_Join_Password_05",
  },
  {
    value: "Mobile_Join_Password_06",
  },
];
